import React, { Component } from 'react';
import { ActivityIndicator, View, Platform, } from 'react-native';
import { init as initPlaybackSdk } from '@zattoo/playback-sdk-react-native';
import { applyClientOptions } from '@zattoo/zapi/lib/helpers/http-client';
import { styles } from './styles';
import { initCookiesShim } from './utils/cookies-shim';
import { Layout } from './layout';
import { SafeAreaView } from './layout/safe-area-view';
import { SessionLoader } from './authentication';
import { ErrorBoundary } from './error-boundary';
import { ChannelsLoader } from './live/channels-loader';
const ZAPI_ENDPOINT = 'https://zapi0004.zattoo.com/zapi/';
const logWebClient = () => {
    if (Platform.OS !== 'web') {
        return;
    }
    console.log(`You are running ${process.env.WEB_CLIENT}`);
};
export class App extends Component {
    state = {
        isReady: false,
    };
    componentDidMount() {
        if (Platform.OS !== 'web') {
            applyClientOptions({ basePath: ZAPI_ENDPOINT });
        }
        // @ts-expect-error incomplete type
        if (Platform.OS === 'kepler') {
            initCookiesShim(ZAPI_ENDPOINT);
        }
        Promise.all([
            initPlaybackSdk(),
        ]).then(() => {
            this.setState({ isReady: true });
        });
        logWebClient();
    }
    render() {
        if (!this.state.isReady) {
            return (React.createElement(View, { style: styles.loadingIndicator },
                React.createElement(ActivityIndicator, { size: "large" })));
        }
        return (React.createElement(SafeAreaView, { style: styles.body },
            React.createElement(ErrorBoundary, null,
                React.createElement(SessionLoader, null,
                    React.createElement(ChannelsLoader, null,
                        React.createElement(Layout, null))))));
    }
}
