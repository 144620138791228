import { isZAPIError, } from '@zattoo/zapi/lib/helpers/error';
import { PlayerWithTimeshift } from '../stream/timeshift';
import { PlayerWithLive } from '../stream/live';
import { PlayerWithReplay } from '../stream/replay';
import { PlayerWithVod } from '../stream/vod';
import { PlayerWithPlay } from '../stream/play';
import { EventKey, PlaybackState, } from './enums';
import { isPlaybackError, PlaybackError, } from '../errors';
import { ErrorCode } from '../errors/enums/error-code';
import { createMediaItem } from './utils';
import { WithProperty } from './modules/property';
import { WithControl } from './modules/control';
import { WithTrack } from './modules/track';
import { WithEvent } from './modules/event';
import { WithTelemetry } from './modules/telemetry';
import { WithDebug } from './modules/debug';
import { WithUtil } from './modules/util';
import { WithPositionListener } from './modules/position-listener';
import { WithError } from './modules/error';
import { WithIma } from './modules/ima';
import { PlayerWithRecording } from '../stream/recording';
export class BasePlayer {
    _canPause = false;
    _canSeekBackward = false;
    _canSeekForward = false;
    _capabilities;
    _currentPosition = null;
    _duration = null;
    _logger;
    _playbackState = PlaybackState.STOPPED;
    _seekableRange = null;
    _streamInformation = null;
    _volume = 1;
    _playerOptions;
    _psid = null;
    _geometry = null;
    _bandwidth = null;
    _bitrate = null;
    _sessions = [];
    adapter;
    triggerTelemetryEvent(payload) {
        payload;
        /* noop */
    }
    triggerTelemetryNewSessionEvent(psid, assetType, assetId) {
        psid;
        assetType;
        assetId;
        /* noop */
    }
    triggerFullEvent(event) {
        event;
        /* noop */
    }
    triggerEvent(event) {
        event;
        /* noop */
    }
    setPlayerState(newState) {
        newState;
        /* noop */
    }
    seekPosition = null;
    constructor(playerOptions, adapter, capabilities) {
        this.adapter = adapter;
        this._playerOptions = playerOptions;
        this._playerOptions.stepBackwardDuration ??= 30;
        this._playerOptions.stepForwardDuration ??= 60;
        this._capabilities = capabilities;
        this._logger = playerOptions.logging?.playerLogger;
    }
    hookMediaItem(media) {
        return media;
    }
    disableTelemetry(psid) {
        psid;
        /* noop */
    }
    dispatchMedia(adapterRequest, adapterMedia, dynamic) {
        const media = createMediaItem(adapterRequest, adapterMedia, dynamic);
        this._streamInformation = {
            mediaItem: media,
            watchResponse: adapterRequest.watchResponse,
            adapterRequest,
        };
        // allows mixins to extend the media item.
        // happens after streamInformation is set,
        // so that mixins have all information available
        const extendedMedia = this.hookMediaItem(media);
        this._streamInformation.mediaItem = extendedMedia;
        this.triggerEvent({
            type: EventKey.MEDIA_CHANGED,
            media: extendedMedia,
            psid: adapterRequest.psid,
        });
        return Promise.resolve(extendedMedia);
    }
    destroy() {
        return this.adapter.destroy();
    }
    get capabilities() {
        return this._capabilities;
    }
    get streamInformation() {
        return this._streamInformation;
    }
    updateMediaType(type) {
        if (!this._streamInformation) {
            return;
        }
        this._streamInformation.mediaItem.mediaType = type;
    }
    get mediaType() {
        if (!this._streamInformation) {
            return null;
        }
        return this._streamInformation.mediaItem.mediaType;
    }
    get psid() {
        return this._psid;
    }
    set psid(newPsid) {
        this._psid = newPsid;
    }
    get bandwidth() {
        return this._bandwidth;
    }
    startNewSession() {
        const sessionAbortController = new AbortController();
        this._sessions.forEach((session) => {
            if (!session.signal.aborted) {
                session.abort();
            }
        });
        this._sessions = [sessionAbortController];
        return sessionAbortController;
    }
    handleError(error, psid) {
        const mappedError = this.mapError(error);
        this.triggerEvent({
            type: EventKey.PLAYER_ERROR,
            error: mappedError,
            psid,
        });
    }
    mapError(error) {
        if (isPlaybackError(error) || isZAPIError(error)) {
            return error;
        }
        return new PlaybackError(ErrorCode.Unknown, String(error));
    }
}
const PlayerWithModules = WithIma(WithDebug(WithPositionListener(WithError(WithUtil(WithTelemetry(WithEvent(WithProperty(WithTrack(WithControl(BasePlayer))))))))));
export const Player = PlayerWithVod(PlayerWithRecording(PlayerWithReplay(PlayerWithTimeshift(PlayerWithLive(PlayerWithPlay(PlayerWithModules))))));
