import { Button, View, Text, } from 'react-native';
import React from 'react';
import { ScrollView } from '../scroll-view';
import { styles } from './styles';
export const ButtonsList = (props) => {
    return (React.createElement(ScrollView, { horizontal: true, style: styles.scroll },
        React.createElement(View, { style: styles.container },
            props.listLabel && (React.createElement(Text, { style: styles.label }, props.listLabel)),
            props.items.map((item) => {
                const title = item.title;
                const id = item.id;
                return (React.createElement(View, { key: id, style: styles.item },
                    React.createElement(Button, { onPress: () => {
                            props.onItemSelected(id);
                        }, title: title, testID: `item-${id}` }),
                    item.metaInfo?.map((metaInfo) => {
                        return (React.createElement(Text, { style: styles.text, key: metaInfo }, metaInfo));
                    })));
            }))));
};
