import { StyleSheet } from 'react-native';
export const styles = StyleSheet.create({
    layout: {
        flex: 1,
    },
    menu: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    menuButton: {
        width: '10%',
    },
    playerContainer: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    miscButtons: {
        flexDirection: 'row',
        padding: 10,
        gap: 10,
    },
});
