import React from 'react';
import { EventKey, } from '@zattoo/playback-sdk-react-native';
import { PlayerManager } from './player-manager';
import { TracksSelector } from './tracks-selector';
export const Track = () => {
    const playerManager = PlayerManager.getInstance();
    const player = playerManager.getPlayer();
    const [audioTracks, setAudioTracks] = React.useState([]);
    const [subtitlesTracks, setSubtitlesTracks] = React.useState([]);
    if (!player) {
        return null;
    }
    player.on(EventKey.AVAILABLE_AUDIO_TRACKS_CHANGED, (event) => {
        setAudioTracks(event.tracks);
    });
    player.on(EventKey.AVAILABLE_SUBTITLES_TRACKS_CHANGED, (event) => {
        setSubtitlesTracks(event.tracks);
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(TracksSelector, { title: "Audio", offSupport: false, tracks: audioTracks, onTrackChanged: (track) => {
                if (!track) {
                    return;
                }
                player.setAudioTrack(track);
            } }),
        React.createElement(TracksSelector, { offSupport: true, title: "Subtitles", tracks: subtitlesTracks, onTrackChanged: (track) => {
                player.setSubtitlesTrack(track);
            } })));
};
