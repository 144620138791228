import React, { Component, } from 'react';
import { Text } from 'react-native';
import { styles } from './styles';
export class ErrorBoundary extends Component {
    state = {
        hasError: false,
        message: null,
    };
    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            message: error.message,
        };
    }
    render() {
        if (this.state.hasError) {
            return (React.createElement(Text, { style: styles.error }, this.state.message || 'An error occurred'));
        }
        return this.props.children;
    }
}
