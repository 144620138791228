import React from 'react';
import { View, Text, StyleSheet, } from 'react-native';
import { styles } from './styles';
const positionStyle = (position) => (StyleSheet.create({
    container: {
        position: 'absolute',
        left: position,
        alignItems: 'baseline',
    },
}));
const layerHeight = 15;
export const Marker = (props) => {
    const markerColor = props.color ? {
        backgroundColor: props.color,
    } : {};
    const position = props.up ? {
        top: -30 - (layerHeight * (props.layer ?? 0)),
    } : {
        top: 5 + (layerHeight * (props.layer ?? 0)),
    };
    return (React.createElement(View, { style: positionStyle(props.position).container },
        React.createElement(View, { style: [styles.marker, markerColor] }),
        React.createElement(Text, { style: [styles.text, position] }, props.text)));
};
