import { StyleSheet } from 'react-native';
export const styles = StyleSheet.create({
    scroll: {
        flexGrow: 0,
    },
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 10,
        paddingBottom: 10,
        paddingStart: 10,
        paddingTop: 10,
    },
    text: {
        color: '#ffffff',
        fontSize: 15,
    },
    label: {
        color: '#ffffff',
        fontSize: 15,
        height: '100%',
    },
    item: {
        height: '100%',
    },
});
