export var EventKey;
(function (EventKey) {
    EventKey["Ready"] = "READY";
    EventKey["Close"] = "CLOSE";
    EventKey["Message"] = "MESSAGE";
})(EventKey || (EventKey = {}));
export class Socket {
    socket = null;
    configuration;
    listeners = new Map();
    constructor(configuration) {
        this.configuration = configuration;
        this.connect();
    }
    connect = () => {
        const { deviceUrl, sessionId, } = this.configuration;
        const endpoint = sessionId
            ? `${deviceUrl}/${sessionId}`
            : deviceUrl;
        this.socket = new WebSocket(endpoint);
        this.socket.addEventListener('open', this.onOpen);
        this.socket.addEventListener('close', this.onClose);
        this.socket.addEventListener('message', this.onMessage);
    };
    dispatchMessage(data) {
        this.dispatchEvent(EventKey.Message, data);
    }
    onOpen = () => {
        this.dispatchEvent(EventKey.Ready);
    };
    onClose = () => {
        this.dispatchEvent(EventKey.Close);
        this.socket = null;
        setTimeout(this.connect, 5000);
    };
    onMessage = async (event) => {
        const eventData = event.data;
        if (eventData.arrayBuffer) {
            const buff = await eventData.arrayBuffer();
            this.dispatchMessage(new Uint8Array(buff));
        }
        else {
            // @ts-expect-error Compatibility
            this.dispatchMessage(new Uint8Array(eventData));
        }
    };
    dispatchEvent(key, data) {
        const eventListeners = this.listeners.get(key);
        if (eventListeners) {
            eventListeners.forEach((listener) => listener({
                type: key,
                detail: data,
            }));
        }
    }
    addEventListener(type, listener) {
        if (!this.listeners.has(type)) {
            this.listeners.set(type, new Map());
        }
        this.listeners.get(type)?.set(listener, listener);
    }
    removeEventListener(type, listener) {
        const eventListeners = this.listeners.get(type);
        if (eventListeners) {
            eventListeners.delete(listener);
        }
    }
    disconnect() {
        this.socket?.removeEventListener('open', this.onOpen);
        this.socket?.removeEventListener('close', this.onClose);
        this.socket?.removeEventListener('message', this.onMessage);
        this.socket?.close();
    }
    send(data) {
        this.socket?.send(data);
    }
}
