import React, { useState, useEffect, } from 'react';
import { ChannelsContext } from './channels-context';
import { getChannelsInfo } from '../utils/epg';
import { useSessionContext } from '../authentication/context';
export const ChannelsLoader = (props) => {
    const session = useSessionContext().session;
    const [channels, setChannels] = useState([]);
    const [groups, setGroups] = useState([]);
    useEffect(() => {
        if (!session) {
            throw new Error('Invalid Session');
        }
        getChannelsInfo(session.power_guide_hash).then((channelsResponse) => {
            setChannels(channelsResponse?.channels ?? []);
            setGroups(channelsResponse?.groups ?? []);
        });
    }, [session]);
    if (!channels) {
        return null;
    }
    return (React.createElement(ChannelsContext.Provider, { value: {
            channels,
            groups,
        } }, props.children));
};
