import { SEEK_FORWARD_MARGIN, SEEK_BACK_MARGIN, } from '../utils';
import { PlaybackState } from '../../../player/enums';
import { MediaType } from '../../../stream/enums';
export const canRegisterTimeshift = (isLive, timeshiftInfo) => {
    return (isLive &&
        timeshiftInfo === null);
};
export const hasRegisteredTimeshift = (timeshiftInfo) => {
    return Boolean(timeshiftInfo);
};
export const isTimeshift = (currentMediaType) => {
    return currentMediaType === MediaType.REGISTERED_TIMESHIFT;
};
export const canSeekForward = (currentMediaType, currentPosition, currentState, seekableRange, forwardSeekingAllowed) => {
    if (currentMediaType !== MediaType.REGISTERED_TIMESHIFT ||
        !forwardSeekingAllowed ||
        !currentPosition ||
        !seekableRange?.end ||
        currentState === PlaybackState.STOPPED) {
        return false;
    }
    return seekableRange.end > currentPosition + SEEK_FORWARD_MARGIN;
};
export const canSeekBackward = (currentMediaType, currentPosition, currentState, seekableRange) => {
    if (currentMediaType !== MediaType.REGISTERED_TIMESHIFT ||
        !currentPosition ||
        seekableRange?.start === undefined ||
        currentState === PlaybackState.STOPPED) {
        return false;
    }
    return currentPosition > seekableRange.start + SEEK_BACK_MARGIN;
};
export const canPause = (timeshiftInfo, currentState, currentMediaType, currentPosition, seekableRange) => {
    if (currentState !== PlaybackState.PLAYING &&
        currentState !== PlaybackState.BUFFERING) {
        return false;
    }
    if (canRegisterTimeshift(currentMediaType === MediaType.LIVE, timeshiftInfo)) {
        return true;
    }
    if (isTimeshift(currentMediaType) &&
        canSeekBackward(currentMediaType, currentPosition, currentState, seekableRange)) {
        return true;
    }
    return false;
};
export const shouldAutostart = (currentMediaType, seekableRange, previousSeekableRange, currentState, currentPosition) => {
    if (!isTimeshift(currentMediaType) ||
        seekableRange === null ||
        previousSeekableRange === null ||
        currentState !== PlaybackState.PAUSED) {
        return false;
    }
    const seekableRangeLength = seekableRange.end - seekableRange.start;
    const previousSeekableRangeLength = previousSeekableRange.end - previousSeekableRange.start;
    const isSeekableRangeGrowing = seekableRangeLength > previousSeekableRangeLength;
    return (!isSeekableRangeGrowing &&
        !canSeekBackward(currentMediaType, currentPosition, currentState, seekableRange));
};
export { getSafeSeekPosition } from '../utils';
