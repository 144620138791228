import { isIOS, isIPad, } from '../../../clients/device-detection';
/**
 * Mute before reload ads to avoid autoplay blocking for ads
 */
export const startMuted = isIOS || isIPad;
/**
 * Support skippable ads for iOS platforms
 *
 * @see https://developers.google.com/interactive-media-ads/docs/sdks/html5/skippable-ads
 */
export const supportSkippableAds = isIOS || isIPad;
/**
 * We need to specify the maximum bitrate because otherwise, IMA loads media files
 * in their lowest possible quality. It happens regardless of the network conditions.
 *
 * @see https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/reference/js/google.ima.AdsRenderingSettings
 */
export const maxBitrate = 2500;
