export const playerInitialState = {
    programStartMs: 0,
    programEndMs: 0,
    streamStartMs: 0,
    streamEndMs: 0,
    currentMs: 0,
    canPlay: false,
    canPause: false,
    canForward: false,
    canBackward: false,
    canPlayLive: false,
    canPlayFromStart: false,
    buffering: false,
    paused: false,
    playing: false,
    stopped: false,
    audioTracks: [],
    subtitleTracks: [],
    currentAudioTrack: undefined,
    currentSubtitleTrack: undefined,
    cueRanges: [],
    stream: undefined,
};
export const initialProgramInfo = {
    preMs: 0,
    postMs: 0,
    programStartEpochMs: 0,
    programEndEpochMs: 0,
    streamType: undefined,
};
