import { AppRegistry } from 'react-native';
import { App, Taf, tafSetUp, } from '@zattoo/sandbox-shared';
import appInfo from './app.json';
if (process.env.TAF_ENABLED === '1') {
    tafSetUp({
        deviceUrl: process.env.TAF_DEVICE_ENDPOINT,
        sessionId: process.env.TAF_SESSION_ID,
    });
    AppRegistry.registerComponent(appInfo.name, () => Taf);
}
else {
    AppRegistry.registerComponent(appInfo.name, () => App);
}
AppRegistry.runApplication(appInfo.name, {
    rootTag: document.getElementById('root'),
});
