import { Player } from './player';
export class WebPlayer extends Player {
    options;
    adapter;
    constructor(options, adapter, capabilities) {
        super(options, adapter, capabilities);
        this.adapter = adapter;
        this.options = options;
    }
    setView(view) {
        this.adapter.setView(view);
    }
}
