export const CustomSubtitleDisplay = (container) => {
    let subtitleArea = null;
    const defaultCellResolution = {
        rows: 15,
        columns: 40,
    };
    const defaultRegionName = 'bitmovinSubtitlesRegion';
    const defaultStyle = 'text-align:center;' +
        'left:5%;' +
        'top:5%;' +
        'width:90%;' +
        'height:90%;' +
        'font-family:sans-serif;' +
        'text-shadow: 1px 1px 1px black, 1px -1px 1px black, -1px 1px 1px black,-1px -1px 1px black;' +
        'color:white;' +
        'position:absolute;';
    const noRegionBackgroundCSSClass = 'bitmovin-subs-r-no-bg';
    let availableRegions = {};
    const addClass = (element, cssClass) => {
        if (!element || !cssClass) {
            return;
        }
        const cl = element.getAttribute('class');
        let isNotPresent = true;
        if (cl && cl.length > 0) {
            const classes = cl.split(' ');
            isNotPresent = classes.indexOf(cssClass) < 0;
        }
        if (cl && cl.length > 0 && isNotPresent) {
            cssClass = `${cl} ${cssClass}`;
        }
        if (isNotPresent) {
            element.setAttribute('class', cssClass);
        }
    };
    const removeClass = (element, cssClass) => {
        if (!element || !cssClass) {
            return;
        }
        let cl = element.getAttribute('class');
        if (cl && cl.indexOf(cssClass) > -1) {
            cl = cl.replace(new RegExp(`(?:^|\\s)${cssClass}(?!\\S)`), '').trim();
            element.setAttribute('class', cl);
        }
    };
    const createHTMLElement = (type, attributes, content) => {
        const el = document.createElement(type);
        if (attributes) {
            Object.keys(attributes).forEach((key) => {
                const value = attributes[key];
                if (attributes.hasOwnProperty(key)) {
                    el.setAttribute(key, value || '');
                }
            });
        }
        if (content) {
            el.innerHTML = content;
        }
        return el;
    };
    const getRegion = (name, style) => {
        name = name || defaultRegionName;
        if (availableRegions.hasOwnProperty(name)) {
            return availableRegions[name];
        }
        const cellResolution = defaultCellResolution;
        const row = style?.match(/cellResolutionRows:([\d]+);/);
        if (row && row.length >= 2) {
            const match = parseInt(row[1] || '', 10);
            if (!isNaN(match)) {
                cellResolution.rows = match;
            }
        }
        const col = style?.match(/cellResolutionColumns:([\d]+);/);
        if (col && col.length >= 2) {
            const match = parseInt(col[1] || '', 10);
            if (!isNaN(match)) {
                cellResolution.columns = match;
            }
        }
        let alwaysShowBackground = false;
        if (!style || style.indexOf('showBackground:always') > -1) {
            alwaysShowBackground = true;
        }
        const regionDiv = document.createElement('div');
        if (style) {
            regionDiv.style.cssText = style;
        }
        else {
            regionDiv.style.left = '5%';
            regionDiv.style.top = '5%';
            regionDiv.style.width = '90%';
            regionDiv.style.height = '90%';
        }
        regionDiv.style.position = 'absolute';
        regionDiv.style.textAlign = 'center';
        const updateFontSize = () => {
            const fontSize = `${Math.round(container.clientHeight / cellResolution.rows)}px`;
            regionDiv.style.fontSize = fontSize;
            regionDiv.style.lineHeight = fontSize;
        };
        updateFontSize();
        if (!alwaysShowBackground) {
            addClass(regionDiv, noRegionBackgroundCSSClass);
        }
        const elem = document.createElement('ul');
        elem.style.bottom = '0';
        elem.style.listStyle = 'none';
        elem.style.position = 'absolute';
        elem.style.listStyle = 'none';
        elem.style.margin = '0 0 10px 0';
        elem.style.padding = '0';
        elem.style.width = '100%';
        elem.style.color = 'white';
        regionDiv.appendChild(elem);
        availableRegions[name] = {
            element: elem,
            region: regionDiv,
            bgAlwaysOn: alwaysShowBackground,
        };
        subtitleArea?.appendChild(regionDiv);
        return availableRegions[name];
    };
    const show = (event) => {
        const region = getRegion(event.region, event.regionStyle);
        if (!region) {
            return;
        }
        const elem = document.createElement('li');
        elem.innerHTML = event.text.replace(/\n/g, '<br/>');
        removeClass(region?.region, noRegionBackgroundCSSClass);
        region.element?.appendChild(elem);
    };
    const hide = (event) => {
        event.region = event.region || defaultRegionName;
        const elem = document.createElement('li');
        elem.innerHTML = event.text.replace(/\n/g, '<br/>');
        if (!availableRegions.hasOwnProperty(event.region)) {
            return;
        }
        const regionList = availableRegions[event.region];
        if (!regionList || !regionList.element) {
            return;
        }
        const children = regionList.element.childNodes;
        const len = children.length;
        let child = null;
        for (let i = 0; i < len; i++) {
            child = children[i];
            if (child && child.innerHTML === elem.innerHTML) {
                regionList.element.removeChild(child);
            }
        }
        if (!regionList.bgAlwaysOn && regionList.element.childNodes.length < 1) {
            addClass(regionList.region, noRegionBackgroundCSSClass);
        }
    };
    const addDefaultRegion = () => {
        getRegion(defaultRegionName, defaultStyle);
    };
    const clear = () => {
        if (subtitleArea) {
            subtitleArea.innerHTML = '';
            availableRegions = {};
            addDefaultRegion();
        }
    };
    const destroy = () => {
        if (subtitleArea && subtitleArea.parentNode) {
            subtitleArea.parentNode.removeChild(subtitleArea);
            subtitleArea = null;
        }
    };
    const init = () => {
        subtitleArea = createHTMLElement('div');
        subtitleArea.setAttribute('id', 'subtitles');
        subtitleArea.style.position = 'absolute';
        subtitleArea.style.bottom = '0';
        subtitleArea.style.width = '100%';
        subtitleArea.style.height = '100%';
        subtitleArea.style.margin = '0';
        subtitleArea.style.padding = '0';
        subtitleArea.style.pointerEvents = 'none';
        addDefaultRegion();
        const renderingElement = container.getElementsByTagName('video')[0] || container.getElementsByTagName('object')[0];
        if (renderingElement && renderingElement.nextSibling) {
            container.insertBefore(subtitleArea, renderingElement.nextSibling);
        }
        else {
            container.appendChild(subtitleArea);
        }
    };
    init();
    return {
        showCue: show,
        hideCue: hide,
        clear,
        destroy,
    };
};
