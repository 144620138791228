import { EventKey } from '../enums';
import { PlaybackError } from '../../errors';
import { ErrorCode } from '../../errors/enums/error-code';
export const WithPositionListener = (Base) => {
    return class PlayerWithUtil extends Base {
        pinRequiredAt = null;
        onPositionChange = (event) => {
            if (!this.pinRequiredAt) {
                return;
            }
            if (this.pinRequiredAt <= event.position) {
                this.handleError(new PlaybackError(ErrorCode.PinRequired, 'Pin required'));
            }
        };
        cleanUpPositionListener() {
            this.off(EventKey.CURRENT_POSITION_CHANGED, this.onPositionChange);
            this.pinRequiredAt = null;
        }
        updatePinRequiredAt() {
            this.pinRequiredAt =
                this._streamInformation?.watchResponse.youth_protection_pin_required_at ??
                    null;
        }
        destroy() {
            this.cleanUpPositionListener();
            return super.destroy();
        }
        stop() {
            this.cleanUpPositionListener();
            return super.stop();
        }
        hookMediaItem(media) {
            this.cleanUpPositionListener();
            this.updatePinRequiredAt();
            if (!this.pinRequiredAt) {
                return super.hookMediaItem(media);
            }
            this.on(EventKey.CURRENT_POSITION_CHANGED, this.onPositionChange);
            return super.hookMediaItem(media);
        }
    };
};
