import cookieParser from 'set-cookie-parser';
const originalFetch = window.fetch;
const storedCookies = {};
const getCookies = () => {
    return Object
        .values(storedCookies)
        .map((entry) => `${entry.name}=${entry.value}`)
        .join('; ');
};
const storeCookies = (headers) => {
    const cookiesHeader = headers.get('Set-Cookie');
    // @ts-expect-error todo
    const splitCookies = cookieParser.splitCookiesString(cookiesHeader);
    const cookies = cookieParser.parse(splitCookies);
    cookies.forEach((entry) => {
        if (storedCookies[entry.name]) {
            delete storedCookies[entry.name];
        }
        if (entry.value) {
            storedCookies[entry.name] = entry;
        }
    });
};
const applyCookies = (headers) => {
    if (!headers) {
        headers = new Headers();
    }
    else if (!(headers instanceof Headers)) {
        headers = new Headers(headers);
    }
    headers.set('Cookie', getCookies());
    return headers;
};
export const initCookiesShim = (domain) => {
    const fetchOverride = (url, options = {}) => {
        if (!url.toString().startsWith(domain)) {
            return originalFetch(url, options);
        }
        // @ts-expect-error todo
        options.headers = applyCookies(options.headers);
        return originalFetch(url, options).then((response) => {
            storeCookies(response.headers);
            return response;
        });
    };
    window.fetch = fetchOverride;
};
