import React from 'react';
import { Button, StyleSheet, View, } from 'react-native';
import { ScrollView } from '../layout/scroll-view';
import { TracksSelector } from './tracks-selector';
import { UTVProgress } from './utv-progress';
import { PlayerApi } from '.';
export const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-end',
        width: '100%',
    },
    hidden: {
        display: 'none',
    },
    scrollContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 10,
        padding: 5,
    },
    label: {
        minWidth: 100,
        color: 'white',
        shadowColor: 'black',
        height: '100%',
        textAlign: 'center',
    },
    scroll: {
        flexGrow: 0,
    },
});
export const UTVOSD = ({ hide, playerState, }) => {
    const player = PlayerApi.current;
    if (!playerState || !player) {
        return null;
    }
    return (React.createElement(View, { style: hide ? styles.hidden : styles.container },
        React.createElement(ScrollView, { horizontal: true, style: styles.scroll },
            React.createElement(View, { style: styles.scrollContainer },
                React.createElement(Button, { onPress: () => {
                        player.play();
                    }, title: "Play", disabled: !playerState.canPlay }),
                React.createElement(Button, { onPress: () => {
                        player.pause();
                    }, title: "Pause", disabled: !playerState.canPause }),
                React.createElement(Button, { onPress: () => {
                        player.playPause();
                    }, title: "Play/Pause" }),
                React.createElement(Button, { onPress: () => {
                        player.stop();
                    }, title: "Stop" }),
                React.createElement(Button, { onPress: () => {
                        player.seek(-1);
                    }, title: "Seek Back", disabled: !playerState.canBackward }),
                React.createElement(Button, { onPress: () => {
                        player.seek(1);
                    }, title: "Seek Forward", disabled: !playerState.canForward }))),
        playerState.audioTracks &&
            React.createElement(TracksSelector, { title: "Audio", offSupport: false, tracks: playerState.audioTracks, onTrackChanged: (track) => {
                    if (!track) {
                        return;
                    }
                    player.setAudioTrack(track.id);
                } }),
        playerState.subtitleTracks &&
            React.createElement(TracksSelector, { title: "Subtitles", offSupport: false, tracks: playerState.subtitleTracks, onTrackChanged: (track) => {
                    player.setSubtitleTrack(track?.id ?? 'off');
                } }),
        React.createElement(UTVProgress, { playerState: playerState, player: player })));
};
