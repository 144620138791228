import { getLiveStream } from '@zattoo/zapi';
import { PlaybackError } from '../errors';
import { ErrorCode } from '../errors/enums/error-code';
import { MediaType } from './enums';
import { getStreamingProtocol } from '../capability';
import { EventKey } from '../player/enums';
import { EventKey as TelemetryEventKey } from '../telemetry/enums/event-key';
import { AssetType } from '../telemetry/enums/asset-type';
import { watchParamsFromCapability, watchParamsFromPlayOptions, watchParamsFromStreamType, watchParamsFromAdapterCapability, } from './utils';
import { generatePsid } from '../player/utils';
export const MAX_TIMESHIFT_OFFSET = 10800;
export const PlayerWithLive = (Base) => {
    return class extends Base {
        watchResponse = null;
        async playLive(channelId, playOptions) {
            try {
                const sessionAbortController = this.startNewSession();
                const psid = generatePsid();
                if (playOptions.enableNativeTelemetry) {
                    this.disableTelemetry(psid);
                }
                this.triggerTelemetryNewSessionEvent(psid, AssetType.LIVE, channelId);
                const capability = this.capabilities.default;
                const adapterCapabilities = await this.adapter.getCapabilities();
                await this.stop();
                this.psid = psid;
                if (sessionAbortController.signal.aborted) {
                    throw new PlaybackError(ErrorCode.SessionAbort, 'Session start aborted by new Session');
                }
                this.updateMediaType(MediaType.LIVE);
                const streamType = getStreamingProtocol(playOptions, capability);
                const param = {
                    cid: channelId,
                    timeshift: MAX_TIMESHIFT_OFFSET,
                    ...watchParamsFromStreamType(streamType),
                    ...watchParamsFromPlayOptions(playOptions),
                    ...watchParamsFromCapability(capability),
                    ...(adapterCapabilities
                        ? watchParamsFromAdapterCapability(adapterCapabilities)
                        : {}),
                };
                this.triggerEvent({
                    type: EventKey.WATCH_REQUESTED,
                    psid,
                });
                this.watchResponse = await getLiveStream(param);
                if (sessionAbortController.signal.aborted) {
                    throw new PlaybackError(ErrorCode.SessionAbort, 'Session start aborted by new Session');
                }
                if (!this.watchResponse) {
                    throw new Error('No watch response');
                }
                this.triggerEvent({
                    type: EventKey.WATCH_RECEIVED,
                    data: this.watchResponse,
                    psid,
                });
                const adapterRequest = {
                    type: MediaType.LIVE,
                    parameters: {
                        cid: channelId,
                    },
                    capability,
                    watchResponse: this.watchResponse,
                    playOptions,
                    psid,
                };
                this.triggerTelemetryEvent({
                    type: TelemetryEventKey.STREAM_LOADING,
                    psid,
                });
                const adapterMedia = await this.adapter.load(adapterRequest);
                if (sessionAbortController.signal.aborted) {
                    throw new PlaybackError(ErrorCode.SessionAbort, 'Session start aborted by new Session');
                }
                const media = await this.dispatchMedia(adapterRequest, adapterMedia);
                return media;
            }
            catch (error) {
                this.handleError(error);
                return Promise.reject(error);
            }
        }
        setPlayerState(newState) {
            if (!this.isLive || this.isAdPlaying()) {
                return super.setPlayerState(newState);
            }
            return super.setPlayerState({
                canPause: false,
                canSeekBackward: false,
                canSeekForward: false,
                seekableRange: null,
                ...newState,
            });
        }
    };
};
