import { StyleSheet } from 'react-native';
export const styles = StyleSheet.create({
    body: {
        flex: 1,
        backgroundColor: '#222222',
    },
    loadingIndicator: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    },
});
