import { getReplayStream } from '@zattoo/zapi';
import { PlaybackError } from '../errors';
import { ErrorCode } from '../errors/enums/error-code';
import { MediaType } from './enums';
import { getStreamingProtocol } from '../capability';
import { EventKey, PlaybackState, } from '../player/enums';
import { watchParamsFromCapability, watchParamsFromPlayOptions, watchParamsFromStreamType, watchParamsFromAdapterCapability, } from './utils';
import { generatePsid } from '../player/utils';
import { AssetType } from '../telemetry/enums/asset-type';
import { EventKey as TelemetryEventKey } from '../telemetry/enums/event-key';
export const PlayerWithReplay = (Base) => {
    return class extends Base {
        get isReplay() {
            return this.mediaType === MediaType.REPLAY;
        }
        watchResponse = null;
        async playProgram(cid, programId, playOptions) {
            try {
                const sessionAbortController = this.startNewSession();
                const psid = generatePsid();
                if (playOptions.enableNativeTelemetry) {
                    this.disableTelemetry(psid);
                }
                this.triggerTelemetryNewSessionEvent(psid, AssetType.REPLAY, String(programId));
                const capability = this.capabilities.default;
                const adapterCapabilities = await this.adapter.getCapabilities();
                await this.stop();
                this.psid = psid;
                if (sessionAbortController.signal.aborted) {
                    throw new PlaybackError(ErrorCode.SessionAbort, 'Session start aborted by new Session');
                }
                this.updateMediaType(MediaType.REPLAY);
                const streamType = getStreamingProtocol(playOptions, capability);
                const dynamic_pvr = playOptions.dynamic ?? true;
                const param = {
                    cid,
                    program_id: programId,
                    // @ts-expect-error not in zapi
                    dynamic_pvr,
                    ...watchParamsFromStreamType(streamType),
                    ...watchParamsFromPlayOptions(playOptions),
                    ...watchParamsFromCapability(capability),
                    ...(adapterCapabilities
                        ? watchParamsFromAdapterCapability(adapterCapabilities)
                        : {}),
                };
                this.triggerEvent({
                    type: EventKey.WATCH_REQUESTED,
                    psid,
                });
                this.watchResponse = await getReplayStream(param);
                if (sessionAbortController.signal.aborted) {
                    throw new PlaybackError(ErrorCode.SessionAbort, 'Session start aborted by new Session');
                }
                if (!this.watchResponse) {
                    throw new Error('No watch response');
                }
                this.triggerEvent({
                    type: EventKey.WATCH_RECEIVED,
                    data: this.watchResponse,
                    psid,
                });
                const adapterRequest = {
                    type: MediaType.REPLAY,
                    parameters: {
                        cid,
                        programId,
                    },
                    capability,
                    watchResponse: this.watchResponse,
                    playOptions,
                    psid,
                };
                this.triggerTelemetryEvent({
                    type: TelemetryEventKey.STREAM_LOADING,
                    psid,
                });
                const adapterMedia = await this.adapter.load(adapterRequest);
                if (sessionAbortController.signal.aborted) {
                    throw new PlaybackError(ErrorCode.SessionAbort, 'Session start aborted by new Session');
                }
                return this.dispatchMedia(adapterRequest, adapterMedia, dynamic_pvr);
            }
            catch (error) {
                this.handleError(error);
                return Promise.reject(error);
            }
        }
        setPlayerState(newState) {
            if (!this.isReplay || this.isAdPlaying()) {
                return super.setPlayerState(newState);
            }
            return super.setPlayerState({
                canPause: this.currentState === PlaybackState.PLAYING || this.currentState === PlaybackState.BUFFERING,
                canSeekBackward: true,
                canSeekForward: this.watchResponse?.stream?.forward_seeking,
                seekableRange: this.seekableRange,
                ...newState,
            });
        }
    };
};
