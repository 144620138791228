import { TimeshiftStatus } from '@zattoo/zapi/lib/stream/enums';
import { PlaybackState } from '../../../player/enums';
import * as timeshiftUtils from './utils';
export const PlayerWithUnregisteredTimeshift = (Base) => {
    return class extends Base {
        unregisteredTimeshiftAvailability = TimeshiftStatus.UNAVAILABLE;
        get #timeshiftCanSeekForward() {
            return timeshiftUtils.canSeekForward(this.currentPosition, this.currentState, this.seekableRange);
        }
        get #timeshiftCanSeekBackward() {
            return timeshiftUtils.canSeekBackward(this.currentPosition, this.currentState, this.seekableRange);
        }
        get #timeshiftCanPause() {
            return timeshiftUtils.canPause(this.currentPosition, this.currentState, this.seekableRange);
        }
        get timeshiftAvailability() {
            if (!this.isLive) {
                return null;
            }
            return this.unregisteredTimeshiftAvailability;
        }
        #updateTimeshiftAvailability(watchResponse) {
            this.unregisteredTimeshiftAvailability = watchResponse.unregistered_timeshift
                ?? TimeshiftStatus.UNAVAILABLE;
        }
        setPlayerState(newState) {
            if (!timeshiftUtils.isModuleResponsible(this.isLive, this.unregisteredTimeshiftAvailability) || this.isAdPlaying()) {
                return super.setPlayerState(newState);
            }
            if (this.currentState === PlaybackState.PAUSED &&
                !this.#timeshiftCanSeekBackward) {
                this.play();
            }
            return super.setPlayerState({
                canPause: this.#timeshiftCanPause,
                canSeekBackward: this.#timeshiftCanSeekBackward,
                canSeekForward: this.#timeshiftCanSeekForward,
                seekableRange: this.seekableRange,
                ...newState,
            });
        }
        hookMediaItem(media) {
            const streamInformation = this.streamInformation;
            if (!this.isLive || !streamInformation) {
                return super.hookMediaItem(media);
            }
            this.#updateTimeshiftAvailability(streamInformation.watchResponse);
            return {
                ...super.hookMediaItem(media),
                timeshiftAvailability: this.unregisteredTimeshiftAvailability,
            };
        }
        seek(position) {
            if (!timeshiftUtils.isModuleResponsible(this.isLive, this.unregisteredTimeshiftAvailability)) {
                super.seek(position);
                return;
            }
            if (!this.seekableRange) {
                return;
            }
            super.seek(timeshiftUtils.getSafeSeekPosition(position, this.seekableRange));
        }
    };
};
