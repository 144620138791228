import React, { useState } from 'react';
import { View, Text, Pressable, StyleSheet, } from 'react-native';
import { styles } from './progress/styles';
import { Marker } from './progress/marker';
const topLayers = 3;
const bottomLayers = 3;
const barStyle = StyleSheet.create({
    bar: {
        width: '100%',
        height: '100%',
        zIndex: 1,
    },
});
export const UTVProgress = ({ playerState, player, }) => {
    const [barWidth, setBarWidth] = useState(0);
    const [seeked, setSeeked] = useState(null);
    let progressLength = 0;
    let progressMin = 0;
    const getUiPosition = (time) => {
        if (playerState.streamStartMs === undefined ||
            playerState.streamEndMs === undefined ||
            playerState.programStartMs === undefined ||
            playerState.programEndMs === undefined ||
            time === undefined) {
            return 0;
        }
        const max = Math.max(playerState.streamEndMs, playerState.programEndMs);
        progressMin = Math.min(playerState.streamStartMs, playerState.programStartMs);
        progressLength = max - progressMin;
        return `${(time - progressMin) / progressLength * 100}%`;
    };
    const seekTo = (time) => {
        player.seekTo(time);
    };
    const onLayout = ({ nativeEvent }) => {
        setBarWidth(nativeEvent.layout.width);
    };
    const onPressButton = ({ nativeEvent }) => {
        // @ts-expect-error layerX is not defined in PointerEvent
        const time = ((nativeEvent.layerX / barWidth) * progressLength) + progressMin;
        setSeeked(time);
        setTimeout(() => {
            setSeeked(null);
        }, 5000);
        seekTo(time);
    };
    return (React.createElement(View, { style: {
            ...styles.progress,
            height: styles.progress.height + ((topLayers + bottomLayers) * 15),
        } },
        React.createElement(View, { style: styles.time },
            React.createElement(Text, { style: styles.timeText }, "Progress")),
        React.createElement(View, { onLayout: onLayout, style: {
                ...styles.bar,
                top: 15 + (topLayers * 15),
            } },
            React.createElement(Pressable, { style: barStyle.bar, onPress: onPressButton }),
            React.createElement(Marker, { text: `t:${playerState.currentMs}`, position: getUiPosition(playerState.currentMs), color: "red", layer: 0 }),
            React.createElement(Marker, { text: `ss:${playerState.streamStartMs}`, position: getUiPosition(playerState.streamStartMs), layer: 1 }),
            React.createElement(Marker, { text: `se:${playerState.streamEndMs}`, position: getUiPosition(playerState.streamEndMs), layer: 2 }),
            React.createElement(Marker, { up: true, text: `ps:${playerState.programStartMs}`, position: getUiPosition(playerState.programStartMs), layer: 0 }),
            React.createElement(Marker, { up: true, text: `pe:${playerState.programEndMs}`, position: getUiPosition(playerState.programEndMs), layer: 1 }),
            seeked !== null &&
                React.createElement(Marker, { up: true, text: `seek:${seeked}`, position: getUiPosition(seeked), color: "yellow", layer: 2 }))));
};
