import { ErrorCode } from '../../errors/enums/error-code';
import { ImaActions, } from './actions';
export const WithImaCallbacksDispatcher = (Base) => {
    return class AdapterWithImaCallbacksDispatcher extends Base {
        actions = ImaActions.getInstance();
        onAdBreakStarted = null;
        onAdBreakEnded = null;
        onAdLoaded = null;
        onAdStarted = null;
        onAdPaused = null;
        onAdResumed = null;
        onAdPositionChanged = null;
        onAdEnded = null;
        onAdSkipped = null;
        onAdError = null;
        onAdMetadata = null;
        onAdPodInfo = null;
        onContentPauseRequested = null;
        onContentResumeRequested = null;
        dispatchAdBreakStarted = () => {
            this.onAdBreakStarted?.();
        };
        dispatchAdBreakEnded = () => {
            this.onAdBreakEnded?.();
        };
        dispatchAdLoaded = (adInfo, podInfo) => {
            this.onAdLoaded?.({
                adId: adInfo?.getAdId(),
                duration: adInfo?.getDuration() ?? 0,
                position: podInfo?.getAdPosition() ?? 0,
                skipTimeOffset: adInfo?.getSkipTimeOffset() ?? 0,
                title: adInfo?.getTitle(),
            });
        };
        dispatchContentPauseRequested = () => {
            this.actions.onAdapterContentPauseRequested();
            this.onContentPauseRequested?.();
        };
        dispatchContentResumeRequested = () => {
            this.actions.onAdapterContentResumeRequested();
            this.onContentResumeRequested?.();
        };
        dispatchAdMetadata = (cuePoints) => {
            this.onAdMetadata?.(cuePoints);
        };
        dispatchAdPodInfo = (podInfo) => {
            this.onAdPodInfo?.({
                index: podInfo?.getPodIndex(),
                total: podInfo?.getTotalAds() ?? 0,
                duration: podInfo?.getMaxDuration() ?? 0,
            });
        };
        dispatchAdStarted = (adData) => {
            this.onAdStarted?.(adData);
        };
        dispatchAdPaused = () => {
            this.onAdPaused?.();
        };
        dispatchAdResumed = () => {
            this.onAdResumed?.();
        };
        dispatchAdPositionChanged = (adData) => {
            this.onAdPositionChanged?.(adData.currentTime);
        };
        dispatchAdEnded = (adInfo) => {
            this.onAdEnded?.({
                ad_id: adInfo?.getAdId(),
            });
        };
        dispatchAdSkipped = () => {
            this.onAdSkipped?.();
        };
        dispatchAdError = (error) => {
            this.onAdError?.({
                code: error.getErrorCode(),
                message: error.message,
            });
        };
        dispatchAdBootError = (error) => {
            this.onAdError?.({
                code: ErrorCode.Ad,
                message: error.message ?? 'IMA SDK boot error',
            });
        };
    };
};
