import { PlaybackError } from '../errors';
import { ErrorCode } from '../errors/enums/error-code';
import { MediaType } from './enums';
import { generatePsid } from '../player/utils';
import { AssetType } from '../telemetry/enums/asset-type';
import { AdType } from '../player/enums/ad-type';
import { EventKey as TelemetryEventKey } from '../telemetry/enums/event-key';
import { StreamProtection, StreamProtocol, } from '../capability';
const detectStreamProtocol = (url) => {
    if (url.match(/\.m3u8/i)) {
        return StreamProtocol.HLS;
    }
    else if (url.match(/\.mpd/i)) {
        return StreamProtocol.DASH;
    }
    else {
        return null;
    }
};
const detectStreamProtection = (laUrl) => {
    if (!laUrl) {
        return StreamProtection.NONE;
    }
    if (laUrl.match(/playready/i)) {
        return StreamProtection.PLAYREADY;
    }
    if (laUrl.match(/widevine/i)) {
        return StreamProtection.WIDEVINE;
    }
    if (laUrl.match(/fairplay/i)) {
        return StreamProtection.FAIRPLAY;
    }
    return null;
};
const findBestCapabilities = (url, laUrl, capabilities) => {
    const streamProtocol = detectStreamProtocol(url);
    if (!streamProtocol) {
        throw new Error("Can't recognize stream protocol");
    }
    const streamProtection = detectStreamProtection(laUrl);
    if (!streamProtection) {
        throw new Error("Can't recognize stream protection");
    }
    const expectedCapability = {
        streamProtocol,
        streamProtection,
    };
    return [capabilities.default, ...capabilities.optional ? capabilities.optional : []].find((capability) => {
        if (capability.streamProtocol !== expectedCapability.streamProtocol) {
            return false;
        }
        if (expectedCapability.streamProtection === StreamProtection.NONE) {
            return true;
        }
        return capability.streamProtection === expectedCapability.streamProtection;
    }) ?? null;
};
export const PlayerWithPlay = (Base) => {
    return class extends Base {
        async playUrl(url, laUrl, playOptions) {
            const sessionAbortController = this.startNewSession();
            const psid = generatePsid();
            if (playOptions.enableNativeTelemetry) {
                this.disableTelemetry(psid);
            }
            this.triggerTelemetryNewSessionEvent(psid, AssetType.LIVE, 'external');
            await this.stop();
            this.psid = psid;
            if (sessionAbortController.signal.aborted) {
                throw new PlaybackError(ErrorCode.SessionAbort, 'Session start aborted by new Session');
            }
            const capability = findBestCapabilities(url, laUrl, this.capabilities);
            if (!capability) {
                throw new Error("Can't find capability for given url and laUrl");
            }
            const trackingOptions = playOptions.telemetryEndpoint ?
                {
                    temp_telemetry_enabled: true,
                    temp_telemetry_url: playOptions.telemetryEndpoint,
                    event_pixel: '',
                    latency_measurement_interval: -1,
                } : undefined;
            const adapterRequest = {
                type: MediaType.URL,
                parameters: null,
                capability,
                watchResponse: {
                    stream: {
                        url,
                        license_url: laUrl ?? undefined,
                        watch_urls: [
                            {
                                url,
                                maxrate: 8000,
                                audio_channel: 'A',
                                license_url: laUrl ?? undefined,
                            },
                        ],
                        event_pixel: '',
                    },
                    csid: '',
                    urls: [],
                    success: true,
                    tracking: trackingOptions,
                },
                playOptions,
                psid,
            };
            if (playOptions?.adsUrl) {
                adapterRequest.watchResponse.stream.ads = [{
                        // TODO: Discuss if we should add placeholder type here
                        type: AdType.PREROLL,
                        vast_url: playOptions.adsUrl,
                    }];
            }
            this.triggerTelemetryEvent({
                type: TelemetryEventKey.STREAM_LOADING,
                psid,
            });
            const adapterMedia = await this.adapter.load(adapterRequest);
            if (sessionAbortController.signal.aborted) {
                throw new PlaybackError(ErrorCode.SessionAbort, 'Session start aborted by new Session');
            }
            await this.dispatchMedia(adapterRequest, adapterMedia);
        }
    };
};
