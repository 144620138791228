import React, { useEffect, useState, } from 'react';
import { UTVPlayerStreamType, } from '@zattoo/playback-sdk-react-native';
import { PlayerManager } from '../player/player-manager';
import { ButtonsList } from '../layout/buttons-list';
import { getChannels, getChannelGroups, getEpg, needsDrm, } from '../utils/epg';
import { useChannels } from '../live/channels-context';
import { useOsd } from '../player/osd-context';
import { useSessionContext } from '../authentication/context';
import { secToMs } from '../utils/time';
import { PlayerApi as NewPlayerApi } from '../player';
export const Live = (props) => {
    const session = useSessionContext().session;
    const channels = useChannels().channels;
    const groups = useChannels().groups;
    const [epg, setEpg] = useState(null);
    const sandboxChannels = channels
        ?.filter((channel) => (getChannels().includes(channel.cid)));
    const { setOsd, osd, } = useOsd();
    useEffect(() => {
        if (!session) {
            return;
        }
        getEpg(session?.lineup_hash, getChannels()).then((response) => {
            setEpg(response?.channels);
        });
    }, [session]);
    const channelGroups = getChannelGroups()
        .map((selected) => ({
        name: groups.find((group) => group.id === selected)?.name ?? '',
        channels: channels?.filter((channel) => channel.group_ids?.includes(selected)),
    }));
    const playLive = (cid) => {
        let player;
        if (process.env.NEW_PLAYER_VIEW) {
            player = NewPlayerApi.current ?? null;
        }
        else {
            const playerManager = PlayerManager.getInstance();
            player = playerManager.getPlayer();
        }
        const channel = channels?.find((c) => c.cid === cid);
        const drmRequired = channel ? needsDrm(channel) : false;
        const now = Date.now() / 1000;
        const program = epg?.[cid]?.find((p) => p.s <= now && p.e > now);
        setOsd({
            channelId: cid,
            programStart: 0,
            programEnd: 0,
        });
        if (process.env.NEW_PLAYER_VIEW) {
            player?.play({
                streamType: UTVPlayerStreamType.LIVE,
                channelId: cid,
                drmRequired,
                programStartEpochMs: secToMs(program?.s),
                programEndEpochMs: secToMs(program?.e),
                pin: osd?.pin,
                programDescriptor: {
                    channelId: cid,
                    programId: program?.id?.toString(),
                },
            });
        }
        else {
            player?.playLive(cid, {
                drmRequired,
                pin: osd?.pin,
            }).then((data) => console.log('playLive', data))
                .catch((error) => console.error('playLive', error));
        }
        props.onChannelSelected(cid);
    };
    if (!channels || !sandboxChannels) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonsList, { onItemSelected: playLive, items: sandboxChannels.map((channel) => ({
                title: channel.title,
                id: channel.cid,
                metaInfo: [`drm: ${needsDrm(channel) ? 'yes' : 'no'}`],
            })), listLabel: "Live" }),
        channelGroups.map((group) => (React.createElement(ButtonsList, { key: group.name, onItemSelected: playLive, items: group.channels.map((channel) => ({
                title: channel.title,
                id: channel.cid,
                metaInfo: [`drm: ${needsDrm(channel) ? 'yes' : 'no'}`],
            })), listLabel: group.name })))));
};
