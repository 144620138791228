import { getVOD } from '@zattoo/zapi';
import { PlaybackError } from '../errors';
import { ErrorCode } from '../errors/enums/error-code';
import { MediaType } from './enums';
import { SubtitlesCodec, getStreamingProtocol, } from '../capability';
import { EventKey, PlaybackState, } from '../player/enums';
import { generatePsid } from '../player/utils';
import { AssetType } from '../telemetry/enums/asset-type';
import { EventKey as TelemetryEventKey } from '../telemetry/enums/event-key';
import { watchParamsFromCapability, watchParamsFromPlayOptions, watchParamsFromStreamType, watchParamsFromAdapterCapability, } from './utils';
export const PlayerWithVod = (Base) => {
    return class extends Base {
        watchResponse = null;
        get isVod() {
            return this.mediaType === MediaType.VOD;
        }
        // eslint-disable-next-line max-params
        async playVod(token, teasableId, teasableType, playOptions) {
            try {
                const sessionAbortController = this.startNewSession();
                const psid = generatePsid();
                if (playOptions.enableNativeTelemetry) {
                    this.disableTelemetry(psid);
                }
                this.triggerTelemetryNewSessionEvent(psid, AssetType.VOD, teasableId);
                const capability = this.capabilities.default;
                const adapterCapabilities = await this.adapter.getCapabilities();
                await this.stop();
                this.psid = psid;
                if (sessionAbortController.signal.aborted) {
                    throw new PlaybackError(ErrorCode.SessionAbort, 'Session start aborted by new Session');
                }
                this.updateMediaType(MediaType.VOD);
                const streamType = getStreamingProtocol(playOptions, capability);
                const param = {
                    teasable_id: teasableId,
                    teasable_type: teasableType,
                    term_token: token,
                    https_watch_urls: true,
                    ...watchParamsFromStreamType(streamType),
                    ...watchParamsFromPlayOptions(playOptions),
                    ...watchParamsFromCapability(capability),
                    ...(adapterCapabilities
                        ? watchParamsFromAdapterCapability(adapterCapabilities)
                        : {}),
                };
                if (capability?.subtitlesCodec) {
                    param.vtt = SubtitlesCodec.VTT === capability.subtitlesCodec;
                    param.ttml = SubtitlesCodec.TTML === capability.subtitlesCodec;
                }
                this.triggerEvent({
                    type: EventKey.WATCH_REQUESTED,
                    psid,
                });
                this.watchResponse = await getVOD(param);
                if (sessionAbortController.signal.aborted) {
                    throw new PlaybackError(ErrorCode.SessionAbort, 'Session start aborted by new Session');
                }
                if (!this.watchResponse) {
                    throw new Error('No watch response');
                }
                this.triggerEvent({
                    type: EventKey.WATCH_RECEIVED,
                    data: this.watchResponse,
                    psid,
                });
                const adapterRequest = {
                    type: MediaType.VOD,
                    parameters: {
                        token,
                        teasableId,
                        teasableType,
                    },
                    capability,
                    watchResponse: this.watchResponse,
                    playOptions,
                    psid,
                };
                this.triggerTelemetryEvent({
                    type: TelemetryEventKey.STREAM_LOADING,
                    psid,
                });
                const adapterMedia = await this.adapter.load(adapterRequest);
                if (sessionAbortController.signal.aborted) {
                    throw new PlaybackError(ErrorCode.SessionAbort, 'Session start aborted by new Session');
                }
                return this.dispatchMedia(adapterRequest, adapterMedia);
            }
            catch (error) {
                this.handleError(error);
                return Promise.reject(error);
            }
        }
        setPlayerState(newState) {
            if (!this.isVod || this.isAdPlaying()) {
                return super.setPlayerState(newState);
            }
            return super.setPlayerState({
                canPause: (this.currentState === PlaybackState.PLAYING ||
                    this.currentState === PlaybackState.BUFFERING),
                canSeekBackward: true,
                canSeekForward: true,
                seekableRange: {
                    start: 0,
                    end: this.seekableRange?.end ?? this.duration ?? 0,
                },
                ...newState,
            });
        }
    };
};
