import { StreamProtocol, StreamProtection, } from '../../capability';
import { pickHighestRate } from '../../stream/utils';
export const createSourceConfiguration = (capabilities, info, options, start) => {
    const config = {};
    if (start) {
        config.options = {
            startOffset: start.startOffset,
            startOffsetTimelineReference: start.startOffsetTimelineReference,
        };
    }
    const licenseUrl = info.license_url
        ?? pickHighestRate(info.watch_urls)?.license_url;
    const streamUrl = info.url
        ?? pickHighestRate(info.watch_urls)?.url;
    switch (capabilities.streamProtocol) {
        case StreamProtocol.DASH:
            config.dash = streamUrl;
            if (!licenseUrl) {
                return config;
            }
            switch (capabilities.streamProtection) {
                case StreamProtection.WIDEVINE:
                    return {
                        ...config,
                        drm: {
                            widevine: {
                                LA_URL: licenseUrl,
                            },
                        },
                    };
                case StreamProtection.PLAYREADY:
                    return {
                        ...config,
                        drm: {
                            playready: {
                                LA_URL: licenseUrl,
                            },
                        },
                    };
                default:
                    throw new Error('Unsupported DRM type');
            }
        case StreamProtocol.HLS:
            config.hls = streamUrl;
            if (!licenseUrl) {
                return config;
            }
            switch (capabilities.streamProtection) {
                case StreamProtection.FAIRPLAY:
                    return {
                        ...config,
                        drm: {
                            fairplay: {
                                LA_URL: licenseUrl,
                                certificateURL: options.fairPlayCertificateUrl,
                                headers: {
                                    'content-type': 'application/data',
                                },
                                prepareMessage(keyMessage) {
                                    return keyMessage.messageBase64Encoded;
                                },
                                prepareContentId(contentId) {
                                    const prefix = 'skd://';
                                    return contentId.substring(contentId.indexOf(prefix) + prefix.length);
                                },
                                useUint16InitData: true,
                            },
                        },
                    };
                default:
                    throw new Error('Unsupported DRM type');
            }
        default:
            throw new Error('Unsupported stream type');
    }
};
