export const injectScript = ({ src, async, id, scriptElement, }) => {
    return new Promise((resolve, reject) => {
        const script = scriptElement || document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        if (id) {
            script.id = id;
        }
        if (async) {
            script.async = true;
        }
        if (!script.onload) {
            script.onload = () => {
                resolve();
            };
        }
        if (!script.onerror) {
            script.onerror = (err) => {
                reject(err);
            };
        }
        document.querySelector('head')?.appendChild(script);
    });
};
