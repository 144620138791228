import * as Zapi from '@zattoo/zapi';
const today = Math.round(Date.now() / 1000);
const day = 24 * 60 * 60;
export const getChannels = () => {
    return [
        'ard',
        'zdf',
        'DE_arte',
        'rtl',
        'cnn-international',
        'rtl_deutschland',
    ];
};
export const getChannelGroups = () => ([
    2, // Jumpy Test Channels
]);
export const getChannelsInfo = (pghash) => {
    return Zapi.getChannelsV4({ pghash });
};
export const getEpg = (lineupHash, cids) => {
    return Zapi.getEPG({
        luhash: lineupHash,
        start: today - day,
        end: today,
        cids,
    });
};
export const needsDrm = (channel) => {
    return Boolean(channel?.qualities.some((quality) => quality.drm_required));
};
