import { EventKey } from './enums/event-key';
import { StreamRepresentation } from '../adapter/enums/stream-representations';
// List of all event types containing status properties
const eventKeysWithStatus = [
    EventKey.BUFFERING,
    EventKey.CLOSE,
    EventKey.CLOSED,
    EventKey.ENDED,
    EventKey.ERROR,
    EventKey.GEOMETRY_CHANGED,
    EventKey.LOG,
    EventKey.PAUSED,
    EventKey.PLAY,
    EventKey.PLAYING,
    EventKey.QUALITY_CHANGED,
    EventKey.READY,
    EventKey.SEEK,
    EventKey.SEEKED,
    EventKey.STATUS,
    EventKey.TRACK_AUDIO_CHANGED,
    EventKey.TRACK_SUBTITLES_CHANGED,
    EventKey.VOLUME_CHANGED,
];
export const isStatusEvent = (event) => {
    // @ts-expect-error runtime check
    return eventKeysWithStatus.includes(event.type);
};
const reduceRepresentation = (representationPrefix, representationOrder) => {
    if (representationOrder.every((value) => !value)) {
        return representationPrefix;
    }
    return `${representationPrefix}${representationOrder.join(';')}`;
};
const formatVideoRepresentation = (representation) => {
    const representationPrefix = 'video/';
    const { bitrate, codec, fps, height, width, } = representation;
    const representationOrder = [
        bitrate,
        (width && height && `${width}x${height}`) ?? null,
        codec ?? null,
        fps ?? null,
    ];
    return reduceRepresentation(representationPrefix, representationOrder);
};
/**
 * @param {$Lib.PlaybackSdk.Representation.AudioRepresentation} representation
 * @returns {string}
 */
const formatAudioRepresentation = (representation) => {
    const representationPrefix = 'audio/';
    const { bitrate, codec, language, } = representation;
    const representationOrder = [
        language,
        bitrate,
        codec ?? null,
    ];
    return reduceRepresentation(representationPrefix, representationOrder);
};
const formatTextRepresentation = (representation) => {
    const representationPrefix = 'text/';
    const { codec, language, } = representation;
    const representationOrder = [
        language,
        codec ?? null,
    ];
    return reduceRepresentation(representationPrefix, representationOrder);
};
const formatRepresentation = (representation) => {
    if (representation.type === StreamRepresentation.VIDEO) {
        return formatVideoRepresentation(representation);
    }
    if (representation.type === StreamRepresentation.AUDIO) {
        return formatAudioRepresentation(representation);
    }
    if (representation.type === StreamRepresentation.TEXT) {
        return formatTextRepresentation(representation);
    }
    return null;
};
export const formatStreamRepresentations = (representations) => {
    return representations
        .map((representation) => formatRepresentation(representation))
        .filter(Boolean);
};
