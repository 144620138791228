export var ImaEventKey;
(function (ImaEventKey) {
    ImaEventKey["AD_BREAK_STARTED"] = "AD_BREAK_STARTED";
    ImaEventKey["AD_BREAK_ENDED"] = "AD_BREAK_ENDED";
    ImaEventKey["AD_LOADED"] = "AD_LOADED";
    ImaEventKey["AD_STARTED"] = "AD_STARTED";
    ImaEventKey["AD_PAUSED"] = "AD_PAUSED";
    ImaEventKey["AD_POSITION_CHANGED"] = "AD_POSITION_CHANGED";
    ImaEventKey["AD_ENDED"] = "AD_ENDED";
    ImaEventKey["AD_SKIPPED"] = "AD_SKIPPED";
    ImaEventKey["AD_ERROR"] = "AD_ERROR";
    ImaEventKey["AD_RESUMED"] = "AD_RESUMED";
    ImaEventKey["CONTENT_PAUSE_REQUESTED"] = "CONTENT_PAUSE_REQUESTED";
    ImaEventKey["CONTENT_RESUME_REQUESTED"] = "CONTENT_RESUME_REQUESTED";
})(ImaEventKey || (ImaEventKey = {}));
