import React from 'react';
import { Button, Text, StyleSheet, } from 'react-native';
import { useSessionContext } from '../authentication/context';
export const styles = StyleSheet.create({
    text: {
        color: '#ffffff',
        fontSize: 15,
    },
});
export const LogoutButton = () => {
    const logout = useSessionContext().logout;
    const name = useSessionContext().session?.account?.name;
    return (React.createElement(React.Fragment, null,
        React.createElement(Text, { style: styles.text }, name),
        React.createElement(Button, { title: "Logout", onPress: logout })));
};
