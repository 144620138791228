import { StreamType as StreamTypeEnum } from '@zattoo/zapi/lib/stream/enums';
import { StreamProtocol } from './stream-protocol';
import { StreamProtection } from './stream-protection';
import { SubtitlesCodec } from './subtitles-codec';
export const getStreamingProtocol = (options, capability) => {
    if (!options.drmRequired) {
        switch (capability.streamProtocol) {
            case StreamProtocol.DASH:
                return StreamTypeEnum.DASH;
            case StreamProtocol.HLS:
                return StreamTypeEnum.HLS7;
            default:
                throw new Error('Unsupported streaming protocol');
        }
    }
    switch (capability.streamProtocol) {
        case StreamProtocol.DASH:
            switch (capability.streamProtection) {
                case StreamProtection.WIDEVINE:
                    return StreamTypeEnum.DASH_WIDEVINE;
                case StreamProtection.PLAYREADY:
                    return StreamTypeEnum.DASH_PLAYREADY;
                default:
                    return StreamTypeEnum.DASH;
            }
        case StreamProtocol.HLS:
            return StreamTypeEnum.HLS7_FAIRPLAY;
        default:
            throw new Error('Unsupported streaming protocol');
    }
};
export { StreamProtocol, StreamProtection, SubtitlesCodec, };
