export var SubtitlesCodec;
(function (SubtitlesCodec) {
    /**
     * Subtitles codec called Web Video Text Tracks (WebVTT).
     */
    SubtitlesCodec["VTT"] = "vtt";
    /**
     * Subtitles codec called Timed Text Markup Language.
     */
    SubtitlesCodec["TTML"] = "ttml";
})(SubtitlesCodec || (SubtitlesCodec = {}));
