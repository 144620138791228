import React, { useEffect, useState, } from 'react';
import { ActivityIndicator, View, Text, } from 'react-native';
import { init as initPlaybackSdk, PlayerView, } from '@zattoo/playback-sdk-react-native';
import { styles } from './styles';
import { useTafPlayerAdapter } from './use-taf-player';
let tafConfiguration = {
    deviceUrl: 'ws://localhost:8002/ws/device',
};
export const tafSetUp = (configuration) => {
    tafConfiguration = configuration;
};
const TafSession = () => {
    const adapter = useTafPlayerAdapter(tafConfiguration);
    if (adapter.player !== null) {
        return (React.createElement(PlayerView, { player: adapter.player, style: {
                width: '100%',
                height: '100%',
                flex: 1,
            } }));
    }
    if (adapter.session !== null) {
        return (React.createElement(View, { style: styles.body },
            React.createElement(Text, { style: styles.description }, "Playback Test Automation Framework"),
            React.createElement(Text, { style: styles.text }, "Connected to taf"),
            adapter.session && (React.createElement(Text, { style: styles.text }, adapter.session.sessionId)),
            React.createElement(Text, { style: styles.description }, "Powered by Zattoo's Stream API and PlaybackSDK")));
    }
    return (React.createElement(View, { style: styles.body },
        React.createElement(Text, { style: styles.text },
            "Connecting...",
            '\n',
            tafConfiguration.deviceUrl),
        React.createElement(ActivityIndicator, { size: "large" })));
};
export const Taf = () => {
    const [isReady, setIsReady] = useState(false);
    useEffect(() => {
        initPlaybackSdk()
            .then(() => {
            setIsReady(true);
        });
    }, []);
    if (!isReady) {
        return (React.createElement(View, { style: styles.body },
            React.createElement(Text, { style: styles.text }, "Warming up..."),
            React.createElement(ActivityIndicator, { size: "large" })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(TafSession, null)));
};
