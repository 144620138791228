import { MediaType } from '../../stream/enums';
export class ImaActions {
    _isAdPlaying = false;
    adapter;
    mediaType = null;
    recoverVolume = null;
    constructor(adapter) {
        this.adapter = adapter;
    }
    isAdPlaying() {
        return this._isAdPlaying;
    }
    static getInstance() {
        return this;
    }
    loadNewSession(mediaType) {
        this._isAdPlaying = false;
        if (this.recoverVolume !== null) {
            this.adapter.setVolume(this.recoverVolume);
        }
        this.mediaType = mediaType;
    }
    onAdapterContentPauseRequested = () => {
        if (this.mediaType === MediaType.LIVE) {
            this.recoverVolume = this.adapter.getVolume();
            this.adapter.setVolume(0);
        }
        else {
            this.adapter.pause();
        }
        this._isAdPlaying = true;
    };
    onAdapterContentResumeRequested = () => {
        this._isAdPlaying = false;
        if (this.recoverVolume !== null) {
            this.adapter.setVolume(this.recoverVolume);
            this.recoverVolume = null;
        }
        if (this.mediaType !== MediaType.LIVE) {
            this.adapter.play();
        }
    };
}
