import React, { Component } from 'react';
import { PlayerManager } from '../player/player-manager';
import { SessionContext } from './context';
import { KeyInput } from './key-input';
import * as crypto from './crypto';
import { encryptedCredentials } from './credentials';
import { ButtonsList } from '../layout/buttons-list';
import { establishSession, getExistingSession, logout, } from './utils';
export class SessionLoader extends Component {
    envKey = process.env.SANDBOX_AUTHENTICATION_KEY;
    state = {
        session: null,
        error: null,
        key: this.envKey ?? '',
        availableCredentials: null,
    };
    componentDidMount() {
        if (this.envKey) {
            this.loadCredentials();
        }
        getExistingSession().then((session) => {
            if (session?.account) {
                PlayerManager.setPublicId(session.account.public_id);
                this.setState({ session });
            }
        });
    }
    loadCredentials() {
        let parsedCredentials = null;
        try {
            const decrypted = crypto.decrypt(encryptedCredentials, this.state.key);
            parsedCredentials = JSON.parse(decrypted);
        }
        catch (error) {
            console.log('decryption error', error);
        }
        if (!parsedCredentials) {
            return;
        }
        const crendetialsEntries = Object.entries(parsedCredentials);
        const credentialsList = crendetialsEntries
            .reduce((credentialsResult, [tenant, credentials]) => {
            return [
                ...credentialsResult,
                ...credentials.map(({ login, password, }) => ({
                    tenant,
                    login,
                    password,
                    id: `${tenant}-${login}`,
                })),
            ];
        }, []);
        this.setState({
            availableCredentials: credentialsList,
        });
    }
    authenticate(login, password, tenant) {
        establishSession(login, password, tenant).then((session) => {
            if (!session.account) {
                throw new Error('Invalid Session');
            }
            PlayerManager.setPublicId(session.account.public_id);
            this.setState({ session });
        }).catch((error) => {
            this.setState({ error });
        });
    }
    handleKeyChange = (key) => {
        this.setState({ key });
    };
    handleKeySubmit = () => {
        this.loadCredentials();
    };
    logout() {
        this.setState({
            session: null,
            key: '',
            availableCredentials: null,
        });
        logout();
    }
    render() {
        if (this.state.error) {
            throw this.state.error;
        }
        if (!this.state.session?.active) {
            return this.state.availableCredentials
                ? (React.createElement(ButtonsList, { listLabel: "Select a user", items: this.state.availableCredentials.map((credentials) => ({
                        title: `${credentials.tenant} - ${credentials.login}`,
                        id: credentials.id,
                    })), onItemSelected: (id) => {
                        const credentials = this.state.availableCredentials?.find((c) => c.id === id);
                        if (credentials) {
                            this.authenticate(credentials.login, credentials.password, credentials.tenant);
                        }
                    } })) : (React.createElement(KeyInput, { value: this.state.key, onChange: this.handleKeyChange, onSubmitEditing: this.handleKeySubmit }));
        }
        return (React.createElement(SessionContext.Provider, { value: {
                logout: this.logout.bind(this),
                session: this.state.session,
            } }, this.props.children));
    }
}
