import React, { createRef, Component, } from 'react';
import { View, } from 'react-native';
export class PlayerView extends Component {
    playerRef = createRef();
    componentDidMount() {
        const player = this.props.player;
        if (this.playerRef.current) {
            player.setView(this.playerRef.current);
        }
    }
    render() {
        return (React.createElement(View, { ref: this.playerRef, style: this.props.style }));
    }
}
