import { StyleSheet } from 'react-native';
export const styles = StyleSheet.create({
    progress: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        height: 60,
    },
    bar: {
        top: 25,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        height: 10,
        width: '80%',
        alignSelf: 'center',
    },
    marker: {
        backgroundColor: 'rgba(0, 255, 255, 0.9)',
        height: 10,
        width: 3,
        borderRadius: 5,
    },
    text: {
        color: 'white',
        textAlign: 'center',
    },
    time: {
        position: 'absolute',
        width: 80,
        left: 5,
    },
    timeText: {
        color: 'white',
        textAlign: 'center',
    },
});
