const formatHdcpVersion = (hdcpVersion) => {
    if (!hdcpVersion) {
        return 'unknown';
    }
    if (hdcpVersion === 'unprotected') {
        return 'none';
    }
    return hdcpVersion;
};
export const formatCapabilities = (capabilities) => {
    if (!capabilities) {
        return [];
    }
    let hdcp;
    if (capabilities.hdcp) {
        hdcp = `hdcp/${formatHdcpVersion(capabilities.hdcp.connectedVersion)};${formatHdcpVersion(capabilities.hdcp.maxSupportedVersion)}`;
    }
    return [
        ...capabilities.audio.map((codec) => `audio/${codec.name}`),
        ...capabilities.video.map((codec) => `video/${codec.name}`),
        ...capabilities.drm.map((drm) => (`drm/${drm.name};${drm.maxSecurityLevel ?? 'unknown'};${drm.version ?? 'unknown'}`)),
        ...(hdcp ? [hdcp] : []),
    ];
};
