import React, { useRef, useEffect, } from 'react';
import { TextInput, View, } from 'react-native';
import { styles } from './styles';
export const KeyInput = (props) => {
    const inputRef = useRef(null);
    useEffect(() => {
        inputRef?.current?.focus();
    }, []);
    return (React.createElement(View, null,
        React.createElement(TextInput, { autoFocus: true, ref: inputRef, style: styles.inputField, value: props.value, onChangeText: props.onChange, placeholder: "Enter authentication key", keyboardType: "numeric", returnKeyType: "done", onSubmitEditing: props.onSubmitEditing, testID: "authentication-key-input" })));
};
