import React from 'react';
import { PlayerView } from '@zattoo/playback-sdk-react-native';
import { PlayerManager } from './player-manager';
import { NewPlayerView, PlayerApi, } from './new-player-view';
import { styles } from './styles';
export { PlayerApi };
export const Player = (props) => {
    if (process.env.NEW_PLAYER_VIEW) {
        return React.createElement(NewPlayerView, { hideOsd: props.hideOsd });
    }
    const playerManager = PlayerManager.getInstance();
    const player = playerManager.getPlayer();
    if (!player) {
        return null;
    }
    return (React.createElement(PlayerView, { player: player, style: styles.player }));
};
