import * as Zapi from '@zattoo/zapi';
import { appTids } from '../app-tids';
export const getExistingSession = async (tenant) => {
    // @ts-expect-error tenant not an enum
    const appTid = (appTids[tenant] ?? appTids.zattoo);
    await Zapi.helloSessionV3({
        app_tid: appTid,
        app_version: '9.9.9',
        uuid: 'ca1e30f8-c257-4471-a8dd-ac3cd6ad04d8',
    });
    const session = await Zapi.getSessionV3();
    if (session?.active && session?.account) {
        return session;
    }
    return null;
};
export const establishSession = async (login, password, tenant) => {
    const session = await getExistingSession(tenant);
    if (session) {
        return session;
    }
    return Zapi.loginAccountV3({
        login,
        password,
    });
};
export const logout = async () => {
    await Zapi.logoutAccountV3();
};
