import * as Zapi from '@zattoo/zapi';
export const getRecordings = () => {
    return Zapi.getNPVRPlaylist();
};
export const formatTitle = (recording) => {
    if (!recording.title) {
        return recording.cid;
    }
    const limit = 20;
    if (recording.title.length <= limit) {
        return recording.title;
    }
    return `${recording.title.slice(0, limit)}...`;
};
