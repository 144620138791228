import React, { useEffect, useState, } from 'react';
import { Teasable } from '@zattoo/zapi/lib/editorial/enums';
import { UTVPlayerStreamType, } from '@zattoo/playback-sdk-react-native';
import { PlayerManager } from '../player/player-manager';
import { getVodTeasers, getTerm, getStreamingOption, } from './utils';
import { PlayerApi as NewPlayerApi } from '../player';
import { ButtonsList } from '../layout/buttons-list';
import { useSessionContext } from '../authentication/context';
export const Vod = () => {
    const { session } = useSessionContext();
    const [movieTeasers, setMovieTeasers] = useState([]);
    const [episodeTeasers, setEpisodeTeasers] = useState([]);
    useEffect(() => {
        const vodPage = session?.content_pages?.vod;
        if (!vodPage || !session) {
            return;
        }
        getVodTeasers({
            pghash: session.power_guide_hash,
            public_id: vodPage,
            with_content_titles: true,
        }).then((teasers) => {
            setMovieTeasers(teasers.movieTeasers);
            setEpisodeTeasers(teasers.episodeTeasers);
        });
    }, [session]);
    const playVod = (teaser) => {
        let player;
        if (process.env.NEW_PLAYER_VIEW) {
            player = NewPlayerApi.current ?? null;
        }
        else {
            const playerManager = PlayerManager.getInstance();
            player = playerManager.getPlayer();
        }
        if (!player) {
            return;
        }
        const term = getTerm(teaser);
        if (!term) {
            throw new Error('No term found');
        }
        const streamingOption = getStreamingOption(term);
        if (!streamingOption) {
            throw new Error('No streaming option found');
        }
        if (process.env.NEW_PLAYER_VIEW) {
            if (teaser.teasable_type === Teasable.VOD_MOVIE) {
                player.play({
                    streamType: UTVPlayerStreamType.VOD_MOVIE,
                    permissionToken: term.token,
                    teasableId: `${teaser.teasable_id}`,
                    drmRequired: term.drm_required,
                });
            }
            else {
                player.play({
                    streamType: UTVPlayerStreamType.VOD_EPISODE,
                    permissionToken: term.token,
                    teasableId: `${teaser.teasable_id}`,
                    drmRequired: term.drm_required,
                });
            }
        }
        else {
            player.playVod(term.token, `${teaser.teasable_id}`, teaser.teasable_type, {
            // drmRequired: term.drm_required,
            }).then((data) => console.log('playVod', data));
        }
    };
    const playVodMovie = (teasableId) => {
        const movie = movieTeasers.find((teaser) => teaser.teasable_id === teasableId);
        if (!movie) {
            throw new Error('No movie found');
        }
        playVod(movie);
    };
    const playVodEpisode = (teasableId) => {
        const episode = episodeTeasers.find((teaser) => teaser.teasable_id === teasableId);
        if (!episode) {
            throw new Error('No episode found');
        }
        playVod(episode);
    };
    const makeTeaserEntries = (teasers) => {
        return teasers.map((teaser) => {
            return {
                title: `${teaser.title.slice(0, 20)}...`,
                id: `${teaser.teasable_id}`,
                metaInfo: [
                    `teasable_id: ${teaser.teasable_id}`,
                ],
            };
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonsList, { onItemSelected: playVodMovie, items: makeTeaserEntries(movieTeasers), listLabel: "Vod Movies" }),
        React.createElement(ButtonsList, { onItemSelected: playVodEpisode, items: makeTeaserEntries(episodeTeasers), listLabel: "Vod Episodes" })));
};
