import { StyleSheet } from 'react-native';
export const styles = StyleSheet.create({
    inputField: {
        height: 50,
        color: '#000000',
        backgroundColor: '#ffffff',
        paddingLeft: 20,
        paddingRight: 20,
    },
});
