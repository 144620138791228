export class ChannelLogger {
    channel;
    logger;
    constructor(channel, logger) {
        this.logger = logger;
        this.channel = channel;
    }
    info(...args) {
        this.logger.info(this.channel, ...args);
    }
    warn(...args) {
        this.logger.warn(this.channel, ...args);
    }
    error(...args) {
        this.logger.error(this.channel, ...args);
    }
}
