export var EventKey;
(function (EventKey) {
    EventKey["NEW_SESSION"] = "NEW_SESSION";
    EventKey["WATCH_SENT"] = "WATCH_SENT";
    EventKey["WATCH_RECEIVED"] = "WATCH_RECEIVED";
    EventKey["PLAYER_INITIALIZED"] = "PLAYER_INITIALIZED";
    EventKey["STREAM_LOADING"] = "STREAM_LOADING";
    EventKey["STREAM_LOADED"] = "STREAM_LOADED";
    EventKey["READY"] = "READY";
    EventKey["BUFFERING"] = "BUFFERING";
    EventKey["PLAY"] = "PLAY";
    EventKey["PLAYING"] = "PLAYING";
    EventKey["PAUSE"] = "PAUSE";
    EventKey["PAUSED"] = "PAUSED";
    EventKey["SEEK"] = "SEEK";
    EventKey["SEEKING"] = "SEEKING";
    EventKey["SEEKED"] = "SEEKED";
    EventKey["QUALITY_CHANGED"] = "QUALITY_CHANGED";
    EventKey["TRACK_AUDIO_CHANGED"] = "TRACK_AUDIO_CHANGED";
    EventKey["TRACK_SUBTITLES_CHANGED"] = "TRACK_SUBTITLES_CHANGED";
    EventKey["NETWORK_CHANGED"] = "NETWORK_CHANGED";
    EventKey["GEOMETRY_CHANGED"] = "GEOMETRY_CHANGED";
    EventKey["VOLUME_CHANGED"] = "VOLUME_CHANGED";
    EventKey["RATE_CHANGED"] = "RATE_CHANGED";
    EventKey["CLOSE"] = "CLOSE";
    EventKey["CLOSED"] = "CLOSED";
    EventKey["ENDED"] = "ENDED";
    EventKey["STATUS"] = "STATUS";
    EventKey["DROPPED_FRAMES"] = "DROPPED_FRAMES";
    EventKey["ERROR"] = "ERROR";
    EventKey["LOG"] = "LOG";
})(EventKey || (EventKey = {}));
