export class ConsoleLogger {
    info(...args) {
        console.info(...args);
    }
    warn(...args) {
        console.warn(...args);
    }
    error(...args) {
        console.error(...args);
    }
}
