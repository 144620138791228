import * as Zapi from '@zattoo/zapi';
const getCollection = (editorialPage, collectionId) => {
    return editorialPage.elements.find((element) => element.element_content_id === collectionId);
};
const getTeasers = (pghash, collection) => {
    if (!collection) {
        return Promise.resolve([]);
    }
    return Zapi.getEditorialTeasers({
        pghash,
        public_id: collection.element_content_id,
        page: 0,
        per_page: 3,
    }).then((editorialTeasers) => editorialTeasers.teasers);
};
export const getVodTeasers = async (params) => {
    const editorialPage = await Zapi.getEditorialPage(params);
    const moviesCollection = getCollection(editorialPage, 'vod_all_movies');
    const episodesCollection = getCollection(editorialPage, 'vod_all_episodes');
    const movieTeasers = await getTeasers(params.pghash, moviesCollection);
    const episodeTeasers = await getTeasers(params.pghash, episodesCollection);
    return {
        movieTeasers,
        episodeTeasers,
    };
};
export const getTerm = (teaser) => {
    if (!teaser) {
        return null;
    }
    const termsCatalog = teaser.teasable.terms_catalog;
    const termCatalog = termsCatalog[0];
    if (!termCatalog) {
        return null;
    }
    const terms = termCatalog.terms;
    const term = terms[0];
    if (!term) {
        return null;
    }
    return term;
};
export const getStreamingOption = (term) => {
    const streamingOptions = term.streaming_options;
    const streamingOption = streamingOptions[0];
    if (!streamingOption) {
        return null;
    }
    return streamingOption;
};
