export const WithTrack = (Base) => {
    return class PlayerWithTrack extends Base {
        setSubtitlesTrack(track) {
            this.adapter.setSubtitlesTrack(track);
        }
        setAudioTrack(track) {
            this._logger?.info('Set audio track', track);
            this.adapter.setAudioTrack(track);
        }
        availableAudioTracks = [];
        availableSubtitlesTracks = [];
        selectedAudioTrack = null;
        selectedSubtitlesTrack = null;
    };
};
