import { Button, StyleSheet, Text, View, } from 'react-native';
import React from 'react';
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 10,
        padding: 5,
    },
    label: {
        minWidth: 100,
        color: 'white',
        shadowColor: 'black',
        height: '100%',
        textAlign: 'center',
    },
    buttonContainer: {
        width: 100,
    },
});
export const TracksSelector = (props) => {
    return (React.createElement(View, { style: styles.container },
        React.createElement(Text, { style: styles.label }, props.title),
        props.offSupport && (React.createElement(View, { style: styles.buttonContainer },
            React.createElement(Button, { onPress: () => props.onTrackChanged(null), title: "Off" }))),
        props.tracks.map((track) => (React.createElement(View, { style: styles.buttonContainer, key: track.id },
            React.createElement(Button, { onPress: () => {
                    props.onTrackChanged(track);
                }, title: track.locale }))))));
};
